.testimonial-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 80vh;
	width: 85vw;
	position: relative;
	z-index: 1;
	scroll-snap-align: center;
}

.testimonial-container .text-side {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50vw;
	height: 100%;
}

.testimonial-container .image-side {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50vw;
	height: 50vw;
}

.testimonial-container .heading {
	color: var(--light);
	font-size: 48px;
	margin: 5px 0;
	text-align: center;
	font-family: var(--bold);
	text-transform: uppercase;
}

.testimonial-container .ratings {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}

.testimonial-container .ratings .rating {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin: 0 5px;
	color: var(--tertiary);
}

.testimonial-container .text p {
	color: var(--light);
	font-family: var(--semibold);
	text-align: center;
	width: 30vw;
	margin-top: 30px;
	flex-wrap: wrap;
	white-space: pre-wrap;
}

.testimonial-container .text h4 {
	color: var(--light);
	font-family: var(--semibold);
	text-align: center;
	margin: 40px 0;
}

.testimonial-container .current-image {
	width: 35vw;
	height: 35vw;
	overflow: hidden;
	z-index: 2;
	border: 2px solid var(--secondary);
	border-radius: 5px;
	margin-bottom: 10px;
}

.testimonial-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.testimonial-container .buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 10px 0;
}

.testimonial-container .buttons .counter {
	color: var(--light);
	font-family: var(--semibold);
	margin: 0 10px;
}

.testimonial-container .prev-next-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border: 2px solid var(--light);
	border-radius: 50%;
	border-right: none;
	border-top: none;
	transition: all 0.5s ease;
	margin: 15px;
}

.testimonial-container .prev-next-icon:hover {
	border-top: 2px solid;
	border-right: 2px solid;
	transform: rotate(360deg);
	background-color: var(--light);
	border-color: var(--light);
	cursor: pointer;
}

.testimonial-container .prev-next-icon .icon {
	color: var(--light);
}

.testimonial-container .prev-next-icon:hover .icon {
	color: var(--secondary);
	transform: scale(1.1);
}

.testimonial-container .quote-image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 100px;
	left: 100px;
	z-index: 1;
}

@media screen and (max-width: 1200px) {
	.testimonial-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: fit-content;
		width: 90vw;
		position: relative;
		z-index: 1;
		scroll-snap-align: center;
		padding: 20px;
	}

	.testimonial-container .text-side {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 50vw;
		height: 100%;
	}

	.testimonial-container .image-side {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		width: 50vw;
		height: 100%;
	}

	.testimonial-container .heading {
		color: var(--light);
		font-size: 44px;
		margin: 5px 0;
		text-align: center;
		font-family: var(--bold);
		text-transform: uppercase;
	}

	.testimonial-container .text p {
		color: var(--light);
		font-family: var(--semibold);
		text-align: center;
		width: 45vw;
		margin-top: 30px;
		flex-wrap: wrap;
		white-space: pre-wrap;
	}

	.testimonial-container .text h4 {
		color: var(--light);
		font-family: var(--semibold);
		text-align: center;
		margin: 30px 0;
	}

	.testimonial-container .current-image {
		width: 40vw;
		height: 50vw;
		overflow: hidden;
		z-index: 2;
		border-radius: 5px;
		margin-bottom: 10px;
	}

	.testimonial-container .quote-image {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50px;
		left: 50px;
		z-index: 1;
	}
}

@media screen and (max-width: 1024px) {
	.testimonial-container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: flex-start;
		height: 100%;
		width: 85vw;
		position: relative;
		z-index: 1;
		scroll-snap-align: center;
		padding: 0 20px;
	}

	.testimonial-container .text-side {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 85vw;
		height: 100%;
		margin-top: 30px;
		margin-left: -15px;
	}

	.testimonial-container .image-side {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 85vw;
		height: 100%;
		margin-top: 30px;
		margin-left: -20px;
	}

	.testimonial-container .heading {
		color: var(--light);
		font-size: 48px;
		margin: 5px 0;
		text-align: center;
		font-family: var(--bold);
		text-transform: uppercase;
	}

	.testimonial-container .text p {
		color: var(--light);
		font-family: var(--semibold);
		text-align: center;
		width: 80vw;
		margin-top: 20px;
		flex-wrap: wrap;
		white-space: pre-wrap;
	}

	.testimonial-container .text h4 {
		color: var(--light);
		font-family: var(--semibold);
		text-align: center;
		margin: 20px 0;
	}

	.testimonial-container .current-image {
		width: 70vw;
		height: 70vw;
		overflow: hidden;
		z-index: 2;
		border-radius: 5px;
		margin-bottom: 10px;
	}

	.testimonial-container .quote-image {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50px;
		left: 50px;
		z-index: 1;
	}
}

@media screen and (max-width: 768px) {
	.testimonial-container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: space-between;
		height: fit-content;
		width: 85vw;
		position: relative;
		z-index: 1;
		scroll-snap-align: center;
	}

	.testimonial-container .text-side {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 85vw;
		height: 100%;
		margin-top: 20px;
		margin-left: -15px;
	}

	.testimonial-container .image-side {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 85vw;
		height: 100%;
		margin-top: 20px;
		margin-left: -20px;
	}

	.testimonial-container .heading {
		color: var(--light);
		font-size: 44px;
		margin: 5px 0;
		text-align: center;
		font-family: var(--bold);
		text-transform: uppercase;
	}

	.testimonial-container .text p {
		color: var(--light);
		font-family: var(--semibold);
		text-align: center;
		width: 80vw;
		margin-top: 20px;
		flex-wrap: wrap;
		white-space: pre-wrap;
	}

	.testimonial-container .text h4 {
		color: var(--light);
		font-family: var(--semibold);
		text-align: center;
		margin: 20px 0;
	}

	.testimonial-container .current-image {
		width: 84vw;
		height: 84vw;
		overflow: hidden;
		z-index: 2;
		border-radius: 5px;
		margin-bottom: 10px;
	}

	.testimonial-container .quote-image {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50px;
		left: 50px;
		z-index: 1;
	}
}

@media screen and (max-width: 480px) {
	.testimonial-container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: space-between;
		height: fit-content;
		width: 90vw;
		position: relative;
		z-index: 1;
		scroll-snap-align: center;
	}

	.testimonial-container .text-side {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 90vw;
		height: 100%;
		margin-top: 10px;
		margin-left: -15px;
	}

	.testimonial-container .image-side {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 90vw;
		height: 100%;
		margin-top: 10px;
		margin-left: -20px;
	}

	.testimonial-container .heading {
		color: var(--light);
		font-size: 28px;
		margin: 5px 0;
		text-align: center;
		font-family: var(--bold);
		text-transform: uppercase;
	}

	.testimonial-container .text p {
		color: var(--light);
		font-family: var(--semibold);
		text-align: center;
		width: 85vw;
		margin-top: 20px;
		flex-wrap: wrap;
		white-space: pre-wrap;
	}

	.testimonial-container .text h4 {
		color: var(--light);
		font-family: var(--semibold);
		text-align: center;
		margin: 20px 0;
	}

	.testimonial-container .current-image {
		width: 89vw;
		height: 89vw;
		overflow: hidden;
		z-index: 2;
		border-radius: 5px;
		margin-bottom: 10px;
	}

	.testimonial-container .quote-image {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 30px;
		left: 30px;
		z-index: 1;
	}
}