.collection-screen {
	position: relative;
	z-index: 1;
	width: 100vw;
    min-height: 100vh;
    background-image: url(../assets/images/background/j-williams-tabzu.jpg);
    background-repeat: repeat;
    background-size: cover;
}

.collection-screen .nav-color{
    background-color: transparent;
    height: 100px;
    width: 100vw;
}

.collection-screen .collection-center{
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.collection-screen .collection-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 50px 50px 50px; 
    background-color: var(--light);
    margin:  50px 0;
    border-radius: 10px;
    width: 70vw;
}

.collection-content h4 {
	color: var(--secondary);
	font-size: 36px;
	margin: 0 0 30px 0;
	text-align: center;
	font-family: var(--bold);
}

.collection-content .images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--secondary);
    width: 100%;
    height: 60vh;
}

.collection-content  swiper-container {
	width: 100%;
	height: 100%;
	color: var(--primary) !important;
	background-color: var(--secondary) !important;
}

.collection-content swiper-slide {
    background-color: var(--secondary);
}

.collection-content .collection-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media screen  and (max-width: 1250px) {
    .collection-screen .collection-content{
        padding: 25px 40px 40px 40px; 
        margin:  40px 0;
        border-radius: 10px;
        width: 75vw;
    }
}

@media screen and (max-width: 1024px) {
    .collection-screen .nav-color{
        background-color: transparent;
        height: 100px;
    }
    .collection-screen .collection-content{
        padding: 20px 40px 40px 40px; 
        margin:  40px 0;
        border-radius: 10px;
        width: 80vw;
    }
    .collection-content h4 {
        font-size: 30px;
        margin: 0 0 20px 0;
    }
}
@media screen and (max-width: 768px) {
    .collection-screen .nav-color{
        background-color: var(--secondary);
        height: 70px;
    }
    .collection-screen .collection-content{
        padding: 10px 20px 20px 20px; 
        margin:  30px 0;
        border-radius: 5px;
        width: 90vw;
    }
    .collection-content h4 {
        font-size: 24px;
        margin: 0 0 10px 0;
    }
    .collection-content .images-container {
        border: 1px solid var(--secondary);
        height: 80vh;
    }
}
@media screen and (max-width: 480px) {
    .collection-screen .nav-color{
        background-color: var(--secondary);
        height: 60px;
    }
    .collection-screen .collection-content{
        padding: 10px 15px 15px 15px; 
        margin:  20px 0;
        border-radius: 5px;
        width: 90vw;
    }
    .collection-content h4 {
        font-size: 20px;
        margin: 0 0 10px 0;
    }
    .collection-content .images-container {
        border: 1px solid var(--secondary);
        height: 80vh;
    }
}
@media screen and (max-width: 320px) {
    .collection-content h4 {
        font-size: 18px;
        margin: 0 0 10px 0;
    }
}