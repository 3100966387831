.contact-screen {
	display: flex;
    background-color: var(--secondary);
    height: fit-content;
    width: 100%;
}

.contact-screen .background{
    background-image: url('../assets/images/background/alexander-andrews.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    padding-top: 180px;
	padding-bottom: 70px;
    display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	align-items: center;
}

.contact-screen .bold {
	color: var(--light);
	font-size: 36px;
	margin: 30px 0;
	text-align: center;
	font-family: var(--bold);
}

.contact-screen .contact-text {
	color: var(--light);
	font-size: 16px;
	font-family: var(--semibold);
	text-align: center;
	width: 50vw;
}

.contact-screen .form {
    margin: 50px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 50vw;
}

.contact-screen .form .form-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
}

.contact-screen .form label {
	color: var(--light);
	font-family: var(--semibold);
	margin: 0 0 10px 0;
    padding-left: 20px;
}

.contact-screen .form-group {
    display: flex;
    align-items: center;
    border: 1px solid var(--light);
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 20px;
	width: 100%;
}

.contact-screen .form .textarea,
.contact-screen .form .input {
    flex-grow: 1;
    padding: 10px 15px;
    border: none;
    outline: none;
    width: 100%;
    height: 50px;
    font-size: 14px;
    color: var(--secondary);
    font-family: var(--regular);
}

.contact-screen .form .textarea:focus,
.contact-screen .form .input:focus {
	outline: none;
}

.contact-screen .form-group-textarea {
    display: flex;
    align-items: center;
    border: 1px solid var(--light);
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 20px;
	width: 100%;
}

.contact-screen .form-group-textarea ::-webkit-scrollbar{
    width: 0;
}

.contact-screen .form .textarea{
    padding: 10px 15px;
    resize: vertical;
}

.contact-screen .btn {
	padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary);
    color: var(--light);
    cursor: pointer;
    height: 60px;
	width: 100%;
    font-size: 14px;
    transition: all 0.5s ease;
    font-family: var(--semibold);
}

.contact-screen .btn:hover {
    background-color: var(--secondary);
    color: var(--light);
	border: 1px solid var(--light);
}

@media screen and (max-width: 1200px) {
	.contact-screen .bold {
		font-size: 34px;
		margin: 30px 0;
	}
	.contact-screen .contact-text {
		color: var(--light);
		font-size: 14px;
		font-family: var(--semibold);
		text-align: center;
		width: 55vw;
	}
	.contact-screen .form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 55vw;
	}
	.contact-screen .form .form-group {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
	}
	.contact-screen .btn {
		width: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.contact-screen .background{
		padding-top: 100px;
		padding-bottom: 50px;
	}
	.contact-screen .bold {
		font-size: 28px;
		margin: 30px 0;
	}
	.contact-screen .contact-text {
		width: 60vw;
	}
	.contact-screen .form {
		margin: 30px 0;
		width: 75vw;
	}
	.contact-screen .form .form-group {
		width: 100%;
	}
	.contact-screen .btn {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.contact-screen .background{
		padding-top: 70px;
		padding-bottom: 35px;
	}
	.contact-screen .bold {
		font-size: 28px;
		margin: 30px 20px;
	}
	.contact-screen .contact-text {
		width: 70vw;
	}
	.contact-screen .form {
		margin: 20px 0;
		width: 85vw;
	}
}

@media screen and (max-width: 480px) {
	.contact-screen .background{
		padding-top: 60px;
		padding-bottom: 30px;
	}
	.contact-screen .bold {
		font-size: 24px;
		margin: 20px 0;
	}
	.contact-screen .contact-text {
		width: 80vw;
	}
	.contact-screen .form {
		margin: 15px 0;
		width: 90vw;
	}
	.contact-screen .form .input {
		padding: 10px 10px;
		height: 40px;
	}
	.contact-screen .btn {
		height: 50px;
	}
}
