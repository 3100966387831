.news-screen {
	position: relative;
	z-index: 1;
	width: 100vw;
    min-height: 100vh;
    background-image: url(../assets/images/background/j-williams-tabzu.jpg);
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news-screen .news-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    min-height: 50vh;
    width: 70vw;
    border: 1px solid var(--light);
	background-color: #34343490;
    border-radius: 10px;
}

.news-screen p {
    color: var(--light);
    font-size: 20px;
    width: 45vw;
    text-align: center;
    margin-bottom: 30px;
    font-family: var(--semibold);
}

.news-screen .newsletter-input {
    display: flex;
    align-items: center;
    border: 1px solid var(--light);
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 20px;
    width: fit-content;
}
  
.news-screen .newsletter-input input[type="email"] {
    flex-grow: 1;
    padding: 10px 20px;
    border: none;
    outline: none;
    width: 500px;
    height: 60px;
    font-size: 14px;
    color: var(--secondary);
    font-family: var(--regular);
}
  
.news-screen .newsletter-input button {
    padding: 8px 16px;
    margin-left: 5px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary);
    color: var(--light);
    cursor: pointer;
    height: 60px;
    width: 200px;
    font-size: 14px;
    transition: all 0.5s ease;
    font-family: var(--semibold);
}
  
.news-screen .newsletter-input button:hover {
    background-color: var(--secondary);
    color: var(--light);
}

@media screen and (max-width: 1200px) {
    .news-screen .news-content{
        min-height: 50vh;
        width: 75vw;
    }
}
@media screen and (max-width: 1024px) {
    .news-screen .news-content{
        padding: 50px 0;
        min-height: 50vh;
        width: 80vw;
        border-radius: 10px;
    }
    .news-screen p {
        font-size: 20px;
        width: 50vw;
        margin-bottom: 30px;
    }
    .news-screen .newsletter-input input[type="email"] {
        padding: 10px 20px;
        width: 400px;
        height: 60px;
    }  
    .news-screen .newsletter-input button {
        padding: 8px 16px;
        margin-left: 5px;
        height: 60px;
        width: 120px;
    }
}
@media screen and (max-width: 768px) {
    .news-screen .news-content{
        padding: 20px 0;
        min-height: 50vh;
        width: 90vw;
        border-radius: 5px;
    }
    .news-screen p {
        width: 80vw;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .news-screen .newsletter-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .news-screen .newsletter-input input[type="email"] {
        width: 85vw;
        height: 50px;
        margin: 2.5px 0 5px 0;
    }  
    .news-screen .newsletter-input button {
        width: 85vw;
        height: 50px;
        margin: 5px 0 2.5px 0;
    }
}
@media screen and (max-width: 480px) {
    .news-screen .news-content{
        padding: 15px 0;
        width: 95vw;
        min-height: 40vh;
    }
    .news-screen p {
        width: 85vw;
        margin-bottom: 15px;
        font-size: 16px;
    }
    .news-screen .newsletter-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .news-screen .newsletter-input input[type="email"] {
        width: 85vw;
        height: 45px;
        margin: 2.5px 0 5px 0;
    }  
    .news-screen .newsletter-input button {
        width: 85vw;
        height: 45px;
        margin: 5px 0 2.5px 0;
    }
}
