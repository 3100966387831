.promoscreen-container {
    background-image: url('../assets/images/background/alexander-andrews.jpg');
    background-size: cover;
    background-position: center;
    height: fit-content;
    width: 100%;
    padding-top: 120px;
	padding-bottom: 60px;
    display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	align-items: center;
}

.promo-container {
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	height: 80vh;
	width: 85vw;
	border-radius: 5px;
	scroll-snap-type: x mandatory;
	align-items: center;
	background-color: #34343490;
}

.promo-container::-webkit-scrollbar {
	display: none;
}

@media screen and (max-width: 1200px) {
	.promo-container {
		display: flex;
		flex-direction: row;
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;
		height: 80vh;
		width: 85vw;
		border-radius: 5px;
		scroll-snap-type: x mandatory;
		align-items: center;
	}
}

@media screen and (max-width: 1024px) {
	.promoscreen-container {
		padding-top: 100px;
		padding-bottom: 50px;
	}
	.promo-container {
		width: 85vw;
		background-color: transparent;
	}
}

@media screen and (max-width: 768px) {
	.promo-container {
		width: 85vw;
	}
	.promoscreen-container {
		padding-top: 70px;
	}
}

@media screen and (max-width: 480px) {
	.promo-container {
		width: 90vw;
	}
	.promoscreen-container {
		padding-top: 60px;
	}
}
