@font-face {
	font-family: 'Poppins-Regular';
	src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins-Medium';
	src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins-SemiBold';
	src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins-Bold';
	src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'GFSDidot-Regular';
	src: url('./assets/fonts/GFSDidot-Regular.ttf') format('truetype');
	font-display: swap;
}

.theme {
	/* Main colors */
	--primary: #CA054D;
	--secondary: #1F2124;
	--tertiary: #ffc107;
	--light: #FFFFFF;
	--gray: #34343420;
	--grey: #FEF9EF10;

	/* Other colors */
	--warning: #f0ad4e;
	--danger: #d9534f;
	--success: #5cb85c;

	/* Fonts */
	--regular: 'Poppins-Regular', sans-serif;
	--medium: 'Poppins-Medium', sans-serif;
	--semibold: 'Poppins-SemiBold', sans-serif;
	--bold: 'Poppins-Bold', sans-serif;
	--regular-2: 'GFSDidot-Regular', sans-serif;
}

body {
	font-family: var(--regular);
	font-size: 14px;
	color: var(--secondary);
	overflow-x: hidden;
	background-color: var(--light);
}

#root {
	max-width: 100%;
	overflow-x: hidden;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
}

html {
	scroll-behavior: smooth;
}

.link {
	text-decoration: none !important;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: var(--light) !important;
}

::-webkit-scrollbar-thumb {
	background: var(--secondary) !important;
}

::-webkit-scrollbar-thumb:hover {
	background: var(--primary) !important;
}