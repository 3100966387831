.classic-screen {
	position: relative;
	z-index: 1;
	width: 100vw;
    min-height: 100vh;
    background-image: url(../assets/images/background/j-williams-tabzu.jpg);
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
}

.classic-screen .nav-color{
    background-color: transparent;
    height: 100px;
    width: 100vw;
}

.classic-screen .classic-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.classic-screen .image-container {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    padding: 5px;
    width: 55vw
}

.classic-screen .row {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.classic-screen .image {
    width: 100%; 
    height: auto; 
    margin-bottom: 10px;
    border: 2px solid var(--light);
}
@media screen and (max-width: 1350px) {
    .classic-screen .image-container {
        width: 65vw
    }
}
@media screen and (max-width: 1200px) {
    .classic-screen .image-container {
        width: 70vw
    }
}
@media screen and (max-width: 1024px) {
    .classic-screen .nav-color{
        background-color: transparent;
        height: 100px;
    }
    .classic-screen .image-container {
        width: 75vw
    }
}
@media screen and (max-width: 768px) {
    .classic-screen .nav-color{
        background-color: var(--secondary);
        height: 70px;
    }
    .classic-screen .image-container {
        width: 85vw;
        padding: 5px;
    }
    .classic-screen .row {
        margin: 2.5px;
    }
    .classic-screen .image {
        margin-bottom: 5px;
        border: 1px solid var(--light);
    }
    .classic-screen .classic-content{
        padding: 30px;
    }
}
@media screen and (max-width: 480px) {
    .classic-screen .nav-color{
        background-color: var(--secondary);
        height: 60px;
    }
    .classic-screen .image-container {
        width: 95vw;
        padding: 5px;
    }
    .classic-screen .row {
        margin: 2.5px;
    }
    .classic-screen .image {
        margin-bottom: 5px;
        border: 1px solid var(--light);
    }
    .classic-screen .classic-content{
        padding: 10px;
    }
}
@media screen and (max-width: 320px) {}