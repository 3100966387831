.about-screen {
	position: relative;
	background-color: var(--background);
	z-index: 1;
	width: 100vw;
}

.about-screen .nav-color{
    background-color: var(--secondary);
    height: 100px;
    width: 100vw;
}

.about-screen .about-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 250px;
}

.about-content .image-container{
    width: 100%;
    height: fit-content;
    overflow: hidden;
    margin-top: 5px;
}

.about-content .image-container:hover .image{
    transform: scale(1.1);
}

.about-screen .md-12{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.about-screen .md-9{
    width: 70%;
    padding: 10px 15px;
}

.about-screen .md-6{
    width: 50%;
    padding: 10px 15px;
}

.about-screen .md-3{
    width: 30%;
    padding: 10px 15px;
}

.about-screen .image {
	width: 100%;
	height: 100%;
	object-fit: cover;
    transition: all 0.5s ease;
}

.about-screen h5 {
    color: var(--secondary);
    font-size: 24px;
    font-family: var(--bold);
    margin: 0 0 5px 0;
}

.about-screen h5 span {
    color: var(--primary);
}

.about-screen .about-content p {
    font-size: 14px;
    font-family: var(--regular);
    color: var(--secondary);
    text-align: justify;
}

.about-screen .about-content i {
    font-family: var(--regular);
    font-size: 14px;
    color: var(--primary);
}

.about-screen .about-content ol li {
    font-size: 14px;
    font-family: var(--regular);
    color: var(--secondary);
    text-align: justify;
    margin-left: 15px;
    margin-bottom: 10px;
}

.about-screen .about-content ul{
    list-style: none;
}

.about-screen .about-content ul li {
    font-size: 14px;
    font-family: var(--regular);
    color: var(--secondary);
    text-align: justify;
    margin-bottom: 10px;
}

@media screen and (max-width: 1024px) {
    .about-screen .about-content{
        margin: 30px 70px;
    } 
    .about-screen .nav-color{
        height: 90px;
    }
}
@media screen and (max-width: 768px) {
    .about-screen .nav-color{
        height: 70px;
    }
    .about-screen .about-content{
        margin: 30px 50px;
    }
    .about-screen .md-12{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: flex-start;
    }
    .about-screen .md-9,
    .about-screen .md-6,
    .about-screen .md-3{
        width: 100%;
        padding: 10px 15px;
    }

}
@media screen and (max-width: 480px) {
    .about-screen .nav-color{
        height: 60px;
    }
    .about-screen .about-content{
        margin: 15px 15px;
    }
    .about-screen .about-content p,
    .about-screen .about-content ol li {
        text-align: left;
    }
}
@media screen and (max-width: 320px) {
    .about-screen .about-content{
        margin: 10px 10px;
    }
}
