.home-screen {
	display: flex;
	position: relative;
	background-color: var(--background);
	height: 100vh;
	justify-content: center;
	align-items: center;
	z-index: 1;
	padding-top: 20px;
	padding-bottom: 60px;
	width: 100vw;
    overflow: hidden;
}

.home-screen .main-image {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 1;
}

.home-screen .promo-button {
	position: absolute;
	bottom: 100px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	width: auto;
	height: 50px;
	padding: 10px 20px;
	border-radius: 25px;
	background-color: var(--primary);
	font-weight: 600;
	cursor: pointer;
}

.home-screen .promo-button:hover {
	border: 2px solid var(--light);
	transition: all 0.5s ease;
}

.home-screen .promo-link {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
	color: var(--light);
	text-decoration: none;
}

.home-screen swiper-container {
	top: 20px;
	width: 100vw;
	height: 100vh;
	color: var(--primary) !important;
	background-color: var(--secondary) !important;
}

.home-screen swiper-slide {
    background-color: var(--secondary);
}

.home-screen swiper-slide:nth-child(2) {
    background-color: var(--primary);
}

.home-screen swiper-slide:nth-child(3) {
    background-color: var(--secondary);
}

.home-screen swiper-slide:nth-child(4) {
	background-color: var(--primary);
}

.home-screen swiper-slide:nth-child(5) {
	background-color: var(--secondary);
}

@media screen and (max-width: 1024px) {}
@media screen and (max-width: 768px) {}
@media screen and (max-width: 480px) {
	.home-screen .promo-button {
		position: absolute;
		bottom: 100px;
		left: 10%;
		transform: translateX(-5%);
		width: 85vw;
	}
}
@media screen and (max-width: 320px) {}