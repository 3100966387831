footer .container {
	display: flex;
	justify-content: space-around;
	align-items: center;	
	width: 100vw;
	padding: 25px 0;
	background-color: var(--secondary);
}
  
footer .container p,
footer .container p a {
	color: var(--light);
	font-family: var(--regular);
	transition: all 0.5s ease;
	text-align: center;
	font-size: 14px;
}

footer .container p a:hover {
	color: var(--primary);
	cursor: pointer;
	text-align: center;
}


footer .container .social-media-icons {
	display: flex;
	align-items: center;
}
  
footer .container .social-media-icons a {
	color: var(--light);
	margin-left: 30px;
	transition: all 0.5s ease;
	font-size: 26px;
}

/* Light Mode Footer */

footer .container-light {
	display: flex;
	justify-content: space-around;
	align-items: center;	
	width: 100vw;
	padding: 25px 0;
	background-color: var(--light);
}
  
footer .container-light p,
footer .container-light p a {
	color: var(--secondary);
	font-family: var(--semibold);
	transition: all 0.5s ease;
	text-align: center;
	font-size: 14px;
}

footer .container-light p a:hover {
	color: var(--primary);
	cursor: pointer;
	text-align: center;
}

footer .container-light .social-media-icons {
	display: flex;
	align-items: center;
}
  
footer .container-light .social-media-icons a {
	color: var(--secondary);
	margin-left: 30px;
	transition: all 0.5s ease;
	font-size: 26px;
}

@media screen and (max-width: 1024px) {
	footer .container,
	footer .container-light {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	footer .social-media-icons{
		margin-top: 20px;
	}
	footer .social-media-icons a {
		margin: 0 15px;
	}
}
@media screen and (max-width: 768px) {
	footer .container,
	footer .container-light {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	footer .social-media-icons{
		margin-top: 20px;
	}
	footer .social-media-icons a {
		margin: 0 10px;
	}
}
@media screen and (max-width: 480px) {
	footer .container,
	footer .container-light {
		padding: 20px 20px;
	}
	footer .container-light .social-media-icons a,
	footer .container .social-media-icons a{
		font-size: 24px;
		margin: 0 8px;
	}
}
@media screen and (max-width: 320px) {
	footer .container,
	footer .container-light {
		padding: 15px 15px;
	}
	footer .container-light .social-media-icons a,
	footer .container .social-media-icons a {
		font-size: 20px;
		margin: 0 10px;
	}
	footer  p,
	footer p a {
		font-size: 14px;
	}
}