.collaborators-screen {
	position: relative;
	z-index: 1;
	width: 100vw;
    min-height: 100vh;
    background-image: url(../assets/images/background/ilya-lix.jpg);
    background-repeat: repeat;
    background-size: cover;
}

.collaborators-screen .nav-color{
    background-color: transparent;
    height: 100px;
    width: 100vw;
}

.collaborators-screen .collaborators-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.collaborators-screen .container{
	width: 80vw;
	display: flex;
    padding: 5px;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.collaborators-screen .collaborator {
    position: relative;
	width: calc(33% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 10px;
    border: 2px solid var(--light);
}

.collaborators-screen .collaborator .info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    width: 100%;
    height: 100%;
}

.collaborators-screen .collaborator .info .heading{
    font-size: 14px;
    font-family: var(--semibold);
    text-align: center;
    margin: 10px 0 5px 0;
    color: var(--light);
}

.collaborators-screen .collaborator .info p{
    font-size: 18px;
    font-family: var(--semibold);
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0 10px 0;
    color: var(--light);
}

.collaborators-screen .collaborator .info a{
    font-size: 14px;
    font-family: var(--semibold);
    text-align: center;
    color: var(--secondary);
    margin: 5px 0 20px 0;
    transition: all 0.5s ease;
    color: var(--light);
}

.collaborators-screen .collaborator .info a:hover{
    color: var(--primary);
}

.collaborators-screen .collaborator .info a .icon{
    margin-right: 10px;
    margin-bottom: -7px;
    transform: rotate(135deg);
    transition: all 0.5s ease;
    color: var(--light);
}

.collaborators-screen .collaborator .info a:hover .icon{
    transform: rotate(0deg);
}

@media screen and (max-width: 1024px) {
    .collaborators-screen .nav-color{
        background-color: transparent;
        height: 100px;
    }
    .collaborators-screen .container{
        width: 85vw;
    }
    .collaborators-screen .collaborator {
        position: relative;
        width: calc(50% - 10px);
        margin: 5px;
        padding: 10px;
        border: 2px solid var(--light);
    }
}
@media screen and (max-width: 768px) {
    .collaborators-screen .nav-color{
        background-color: var(--secondary);
        height: 70px;
    }
    .collaborators-screen .container{
        width: 95vw;
    }
    .collaborators-screen .collaborators-content{
        padding: 30px 0;
    }
    .collaborators-screen .collaborator {
        position: relative;
        width: calc(50% - 10px);
        margin: 2.5px;
        padding: 5px;
        border: 1px solid var(--light);
    }
}
@media screen and (max-width: 480px) {
    .collaborators-screen .nav-color{
        background-color: var(--secondary);
        height: 60px;
    }
    .collaborators-screen .container{
        width: 95vw;
    }
    .collaborators-screen .collaborators-content{
        padding: 20px 0;
    }
    .collaborators-screen .collaborator {
        width: calc(100% - 10px);
    }
}
@media screen and (max-width: 320px) {
    .collaborators-screen .collaborator {
        width: 100%
    }
    .collaborators-screen .collaborators-content{
        padding: 10px 0;
    }
}
