.nav-bar {
	top: 0;
	width: 100vw;
	height: 100px;
	z-index: 999;
	display: flex;
	position: absolute;
    flex-direction: row;    
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #0D182140, #0D182110);
}
.nav-bar .nav-menu{
	display: grid;
	justify-content: center;
	align-items: center;
	list-style: none;
	grid-template-columns: repeat(7, auto);
}
.nav-bar .nav-link{
    text-decoration: none;
    color: var(--light);
    font-size: 14px;
    cursor: pointer;
    width: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    transition: all 0.5s ease;
}
.nav-bar .nav-link:hover{
    color: var(--primary);
}
.nav-bar .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    padding: 0 10px;
    height: 100px;
    width: 100px;
    cursor: pointer;
    overflow: hidden;
}
.nav-bar .logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
}
.nav-bar .logo-container:hover .logo {
    transform: scale(1.1);
}
.nav-bar .dropdown-content {
    position: absolute;
    background-color: var(--secondary);
    z-index: 1;
    flex-direction: column; 
    justify-content: flex-start;
    overflow: hidden;
    transition: all 0.5s ease; 
    top: -200%;
}
.nav-bar .nav-link:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease; 
    top: 90px;
}
.nav-bar .dropdown-content .nav-link {
    color: var(--light);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    transition: all 0.5s ease;
    min-width:  200px !important;
}
.nav-bar .dropdown-content .nav-link:hover {
    background-color: var(--light);
    color: var(--primary);
}
.nav-bar .mobile-dropdown-content{
    display: none;
}
.nav-bar .desktop-view {
    display: none;
}
.nav-bar .nav-link.dropdown.mobile,
.nav-bar .mobile-view {
    display: none;
}
.nav-bar .desktop-view {
    display: flex;
}
.nav-bar .menu-icon {
    display: none;
}

@media screen and (max-width: 1024px) {
    .nav-bar {
        height: 80px;
    }
    .nav-bar .logo-container {
        height: 80px;
        width: 80px;
    }
    .nav-bar .nav-link {
        font-size: 12px;
        width: 100%;
        min-height: 80px;
    }
    .nav-bar .nav-link.dropdown.mobile{
        display: flex;
    }
    .nav-bar .dropdown{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .nav-bar .nav-link.dropdown.desktop-view,
    .nav-bar .desktop-view {
        display: none;
    }
    .nav-bar .dropdown-content{
        display: none;
    }
    .nav-bar .mobile-dropdown-content{
        display: none;
    }
    .nav-bar .mobile-dropdown-content.active{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        width: 100%;
        height: fit-content;
        margin-top: 10px;
    }
    .nav-bar .mobile-dropdown-content .nav-link {
        font-size: 12px;
        width: 100%;
        background-color: var(--gray);
    }
    .nav-bar .mobile-view {
        display: flex;
        left: 50px;
        position: absolute;
        top: 10px;
    }
    .nav-bar .menu-icon {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 50px;
        top: 35px;
        z-index: 999;
    }
    .nav-bar .menu-icon .icon {
        width: 26px;
        height: 26px;
        color: var(--light);
    }
    .nav-bar .nav-menu{
        position: absolute;
		top: 80px;
		display: flex;
		flex-direction: column;
		justify-items: flex-start;
		align-items: flex-start;
		width: 100vw;
		height: fit-content;
		left: -100vw;
		opacity: 0;
		transition: all 0.5s ease;
		background-color: var(--secondary);
        z-index: 999;
    }
    .nav-bar .nav-menu.active{
        left: 0;
		opacity: 1;
		z-index: 1;
    }
}
@media screen and (max-width: 768px) {
    .nav-bar {
        height: 60px;
    }
    .nav-bar .nav-menu{
		top: 60px;
    }
    .nav-bar .logo-container {
        height: 60px;
        width: 60px;
    }
    .nav-bar .nav-link {
        min-height: 70px;
    }
    .nav-bar .mobile-view {
        left: 40px;
        top: 5px;
    }
    .nav-bar .menu-icon {
        right: 50px;
        top: 25px;
    }
}

@media screen and (max-width: 480px) {
    .nav-bar {
        height: 50px;
    }
    .nav-bar .logo-container {
        height: 50px;
        width: 50px;
    }
    .nav-bar .mobile-view {
        left: 10px;
        top: 5px;
    }
    .nav-bar .menu-icon {
        right: 20px;
        top: 20px;
    }
}

@media screen and (max-width: 320px) {}



